import fileCrypto from './crypto.js'
import jobQueue from './jobQueue.js'
import previewManager from './previewmanager.js'


export default class uiManger {

	constructor( datasource ) {

		this.filecrypto = new fileCrypto();
		this.queue = new jobQueue( this, this.filecrypto );
		this.ds = datasource;
		this.previewmanager = new previewManager( this.ds, this.filecrypto )
		this.cwd = '/';
		this.cwdConfig = { files:[] };

		this.fileListMainEl = document.getElementById("fileListMain");
		this.fileUploadInput = document.getElementById("encfileElem");
		this.fileUploadBtn = document.getElementById("uploadFileBtn");

		this.bindEventHandlers();

		//this.buildCwdWindow()
		this.changeCwd('/');

	}

	bindEventHandlers() {

		let _self = this

		this.fileUploadInput.addEventListener('change', async function(e) {

			let fileData = await _self.filecrypto.readfile( e.target.files[0] )
			var filename = e.target.files[0].name

			let blob = new Blob([fileData], {type: e.target.files[0].type});
			let previewBase64 = await _self.previewmanager.createPreview( blob )
			//console.log( previewBase64 )

			let encBlob = _self.filecrypto.encryptfile( fileData, filename );

			encBlob.then(async (data) => {
				let guid = _self.generate_uuidv4()

				//await _self.ds.uploadFile( data.blob, guid+'.enc', _self.cwd )
				_self.queue.addJob({
    				jobType: "upload",
    				data: {
    					blob: data.blob,
    					filename: guid+'.enc',
    					cwd: _self.cwd
    				 }
    			});

				await _self.addToGDI( guid, _self.cwd, filename, previewBase64 )
				_self.buildCwdWindow()
			})
		})

		this.fileUploadBtn.addEventListener('click', function(e) {
			encfileElem.click();
		})

		document.addEventListener("click", function(e){
  			const target = e.target.closest(".fileDiv");

  			if(target){
    			if( target.dataset.isfolder == "true" ) {
    				_self.changeCwd( target.dataset.path )
    			} else {
    				_self.queue.addJob({
    					jobType: "download",
    					data: { url: target.dataset.path }
    				});
    			}
  			}
		});
	}

	async changeCwd( path ) {

		if( !path.startsWith('/') ) path = '/'+path;

		this.cwd = path;

		let cwdDisplay = document.getElementById("cwdDisplay")
		cwdDisplay.innerHTML = this.cwd;

		this.cwdConfig = await this.ds.getPathConfig( path );
		this.buildCwdWindow();
	}

	loadMetainfo() {
		console.log( 'loadMetainfo' )
		//console.log( this.cwdConfig )
		let _self = this

		var files = document.querySelectorAll(".fileDiv");

		//console.log(files)

		for(let i=0; i < files.length; i++) {
			if( files[i].dataset.metaloaded == "false" ) {
				let meta = _self.cwdConfig.files.filter(f => files[i].dataset.guid == f.guid)
				if( meta.length ) {
					let preview = files[i].children[0]
					let info = files[i].children[1]
					info.innerHTML = meta[0].filename
					console.log( meta )
					preview.style['background-repeat'] = 'no-repeat';
					preview.style['background-image'] = 'url("' + meta[0].preview + '")';
					preview.style['background-position'] = 'center';
					preview.style['background-size'] = '75%';
				}
			}
		}

	}

	buildCwdWindow() {

		let _self = this

		let cwdObjects = this.ds.listObjects( this.cwd )

		this.fileListMainEl.innerHTML = '';

		cwdObjects.then( response => {
			response.forEach(el => {
				let isFolder = ( el.Key.endsWith('/') ) ? true : false;

				let fileDiv = document.createElement("div");
				fileDiv.classList.add("fileDiv");
				if( !isFolder ) {
					fileDiv.dataset.guid = el.Key.substr( 0, el.Key.length-4 )
					fileDiv.dataset.metaloaded = false
				}

				let fileDivPreview = document.createElement("div");
				fileDivPreview.classList.add("fileDivPreview")
				if( isFolder ) fileDivPreview.classList.add("fileDivPreviewIsFolder")

				let fileDivTitle = document.createElement('div');
				fileDivTitle.classList.add("fileDivTitle")
				fileDivTitle.innerHTML = el.Key

				fileDiv.append(fileDivPreview)
				fileDiv.append(fileDivTitle)

				fileDiv.dataset.path = el.Key
				fileDiv.dataset.isfolder = isFolder
				
				this.fileListMainEl.append( fileDiv )
			})

			_self.loadMetainfo()
		});

		//this.loadMetainfo();
	}

	getCwd() {
		return this.cwd;
	}

	async addToGDI( uuid, path, filename, previewBase64 ) {
		console.log( 'addToGDI' )
		const result = this.cwdConfig.files.filter(file => file.guid == uuid);
		this.cwdConfig.files.push({
			guid: uuid,
			filename: filename,
			preview: previewBase64
		})
		await this.ds.putPathConfig( path, this.cwdConfig )
		console.log( this.cwdConfig )
	}

	generate_uuidv4() {
		var dt = new Date().getTime();
   		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
   			function( c ) {
      			var rnd = Math.random() * 16;//random number in range 0 to 16
      			rnd = (dt + rnd)%16 | 0;
      			dt = Math.floor(dt/16);
      			return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
   		});
	}
		
}

