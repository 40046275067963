export default class dataSource {
	constructor() {
		this.apiEndpoint = 'https://storage.octostorage.io'
	}

	async listObjects( path ) {
		const response = await fetch( this.apiEndpoint+path )

		if(!response.ok) {
			console.log("Error listObjects " + path);
			console.log( response )
			return;
		}

		const objs = await response.json()
		return objs;
	}

	async uploadFile(uploadBlob, filename, cwd) {
    	let formData = new FormData();

    	formData.append("upload", uploadBlob, filename)

    	let uploadPath = cwd + filename

    	await fetch(this.apiEndpoint+uploadPath, {
      		method: "POST", 
      		body: formData
    	});

    	console.log('The file has been uploaded successfully.');
	}

	async getPathConfig( path ) {
		console.log('getPathConfig ' + path)
		const response = await fetch( this.apiEndpoint+path+'.gdi' )

		if( response.status == 404 ) {
			console.log( 'Return default pathConfig' )
			return { files:[] };
		}

		if(!response.ok) {
			console.log("Error getPathConfig " + path);
			console.log( response )
			return;
		}

		const objs = await response.json()
		console.log( objs )
		return objs;
	}

	async putPathConfig( path, config ) {
		let filename = '.gdi'
		let uploadPath = path + filename

		console.log("putPathConfig to " + uploadPath)
		console.log( config )

		const str = JSON.stringify( config );
		const bytes = new TextEncoder().encode(str);
		const blob = new Blob([bytes], {
    		type: "application/json;charset=utf-8"
		});


		let formData = new FormData();
    	formData.append("upload", blob, filename)

    	await fetch(this.apiEndpoint+uploadPath, {
      		method: "POST", 
      		body: formData
    	});		
	}

}
