// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/icons8-folder-128.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html, body, .container-fluid { height: 100%; }\n\n.jobDiv { background-color: #848884; padding-top: 0.5em; border-bottom: 2px solid #71797E; overflow: hidden; }\n.jobDivProgress { float: left; width: 90%; }\n.jobDivProgressInner { height: 10px; background-color: red; width: 90%; }\n.jobDivCancel { float: right; width: 10%; opacity: 0.2; }\n\n.fileDiv { display: inline-block; height: 160px; width: 120px; margin-left: 10px; margin-bottom: 20px; border: 1px solid transparent; overflow: hidden; }\n.fileDivPreview { width: 100%; height: 120px; }\n.fileDivTitle { width: 100%; overflow: hidden; text-align: center; font-size: 75%; color: #D3D3D3; }\n.fileDiv:hover { border: 1px solid #d0d0d0; }\n.fileDivPreviewIsFolder { background-repeat: no-repeat; background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); background-position: center; background-size: 75%; }", ""]);
// Exports
module.exports = exports;
