export default class fileCrypto {

	readfile(file) {
		return new Promise((resolve, reject) => {
			var fr = new FileReader();  
			fr.onload = () => {
				resolve(fr.result )
			};
			fr.readAsArrayBuffer(file);
		});
	}

	async encryptfile( fileData, filename ) {

		//var plaintextbytes = await this.readfile(objFile)
		//.catch(function(err){
		//	console.error(err);
		//});	
		var plaintextbytes = new Uint8Array(fileData);

		var pbkdf2iterations=10000;
		var passphrasebytes=new TextEncoder("utf-8").encode("Password123");
		var pbkdf2salt=window.crypto.getRandomValues(new Uint8Array(8));

		var passphrasekey=await window.crypto.subtle.importKey('raw', passphrasebytes, {name: 'PBKDF2'}, false, ['deriveBits'])
		.catch(function(err){
			console.error(err);
		});
		//console.log('passphrasekey imported');

		var pbkdf2bytes=await window.crypto.subtle.deriveBits({"name": 'PBKDF2', "salt": pbkdf2salt, "iterations": pbkdf2iterations, "hash": 'SHA-256'}, passphrasekey, 384)		
		.catch(function(err){
			console.error(err);
		});
		//console.log('pbkdf2bytes derived');
		pbkdf2bytes = new Uint8Array(pbkdf2bytes);

		let keybytes = pbkdf2bytes.slice(0,32);
		let ivbytes = pbkdf2bytes.slice(32);

		var key=await window.crypto.subtle.importKey('raw', keybytes, {name: 'AES-CBC', length: 256}, false, ['encrypt']) 
		.catch(function(err){
			console.error(err);
		});
		//console.log('key imported');		

		var cipherbytes = await window.crypto.subtle.encrypt({name: "AES-CBC", iv: ivbytes}, key, plaintextbytes)
		.catch(function(err){
			console.error(err);
		});

		if(!cipherbytes) {
			console.log('Error encrypting file.  See console log.');
			return;
		}

		//console.log('plaintext encrypted');
		cipherbytes = new Uint8Array(cipherbytes);

		var resultbytes = new Uint8Array(cipherbytes.length+16)
		resultbytes.set(new TextEncoder("utf-8").encode('Salted__'));
		resultbytes.set(pbkdf2salt, 8);
		resultbytes.set(cipherbytes, 16);

		var blob = new Blob([resultbytes], {type: 'application/download'});

		//console.log(blob)

		//console.log('Upload')
		//uploadFile(blob, objFile.name+'.enc');
		let encFilename = filename+'.enc'
		return { blob, encFilename };
	}

	async decryptfile( encBlob ) {

		var cipherbytes = await encBlob.arrayBuffer()
		.catch(function(err){
			console.error(err);
		});
		console.log( cipherbytes )	
		var cipherbytes = new Uint8Array(cipherbytes);

		var pbkdf2iterations = 10000;
		var passphrasebytes = new TextEncoder("utf-8").encode("Password123");
		var pbkdf2salt = cipherbytes.slice(8,16);


		var passphrasekey = await window.crypto.subtle.importKey('raw', passphrasebytes, {name: 'PBKDF2'}, false, ['deriveBits'])
		.catch(function(err){
			console.error(err);
		});
		console.log('passphrasekey imported');

		var pbkdf2bytes = await window.crypto.subtle.deriveBits({"name": 'PBKDF2', "salt": pbkdf2salt, "iterations": pbkdf2iterations, "hash": 'SHA-256'}, passphrasekey, 384)		
		.catch(function(err){
			console.error(err);
		});
		console.log('pbkdf2bytes derived');
		pbkdf2bytes = new Uint8Array(pbkdf2bytes);

		let keybytes = pbkdf2bytes.slice(0,32);
		let ivbytes = pbkdf2bytes.slice(32);
		cipherbytes = cipherbytes.slice(16);

		var key = await window.crypto.subtle.importKey('raw', keybytes, {name: 'AES-CBC', length: 256}, false, ['decrypt']) 
		.catch(function(err){
			console.error(err);
		});
		console.log('key imported');		

		var plaintextbytes=await window.crypto.subtle.decrypt({name: "AES-CBC", iv: ivbytes}, key, cipherbytes)
		.catch(function(err){
			console.error(err);
		});

		if(!plaintextbytes) {
		 	//spnDecstatus.classList.add("redspan");
			console.log('Error decrypting file.  Password may be incorrect.');
			return;
		}

		console.log('ciphertext decrypted');
		plaintextbytes = new Uint8Array(plaintextbytes);

		var blob = new Blob([plaintextbytes], {type: 'application/download'});

		return blob;

		//var blobUrl = URL.createObjectURL(blob);
		//aDecsavefile.href=blobUrl;
		//aDecsavefile.download=objFile.name + '.dec';

	 	//spnDecstatus.classList.add("greenspan");
		//spnDecstatus.innerHTML='<p>File decrypted.</p>';
		//aDecsavefile.hidden=false;
	}
}