import './css/style.css'

const bip39 = require('bip39')

import dataSource from './js/datasource.js'
import uiManager from './js/uimanager.js'



sessionStorage.setItem("gd_mnemonic", "tonight seven pelican tattoo erode fine spice tooth shallow captain rifle earth");
sessionStorage.setItem("gd_key", bip39.mnemonicToSeedSync( sessionStorage.getItem("gd_mnemonic")).toString('hex') );



const ds = new dataSource();
const ui = new uiManager( ds );


if (module.hot)       // eslint-disable-line no-undef
  module.hot.accept() // eslint-disable-line no-undef