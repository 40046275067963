export default class previewManager {
	constructor( datasource, filecrypto ) {
		this.datasource = datasource
		this.filecrypto = filecrypto
		this.previewHeight = 64
		this.previewWidth = 64

		this.isOnion = window.location.hostname.match(/\.onion/)

		const { createFFmpeg, fetchFile } = FFmpeg;
		this.fetchFile = fetchFile;

		if( this.isOnion ) {
    			this.ffmpeg = createFFmpeg({
				mainName: 'main',
				corePath: 'https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js',
				log: true
			});
		} else {
			this.ffmpeg = createFFmpeg({
                                log: true
                        });
		}

		let newCanvas = document.createElement("canvas");
		newCanvas.id = 'previewCanvas'
		newCanvas.width = 64
		newCanvas.height = 64
		newCanvas.style['display'] = 'none';

		document.body.append( newCanvas )
		this.previewCanvas = document.getElementById("previewCanvas")
	}

	createPreview( blob ) {
		//console.log( blob )
		switch( blob.type ){
			case 'image/gif':
			case 'image/jpeg':
			case 'image/png':
				return this.createPreviewImage( blob );
				break;
			case 'video/mp4':
				return this.createPreviewVideo( blob );
		}
	}

	createPreviewVideo( blob ) {
		let _self = this
		return new Promise(async (resolve, reject) => {
			console.log( blob )
			await _self.ffmpeg.load();
			await _self.ffmpeg.FS('writeFile', 'input.mp4', await _self.fetchFile(blob));
			await _self.ffmpeg.run('-ss', '00:00:15', '-i', 'input.mp4', '-frames:v', '1', 'out.png');
        	const data = _self.ffmpeg.FS('readFile', 'out.png');
        	//console.log( data )
var b = new Blob([data], {'type': 'image/png'});

var img = new Image();
var ctx = _self.previewCanvas.getContext('2d');

                img.onload = function () {
                        ctx.drawImage(img,0,0, _self.previewWidth, _self.previewHeight);
                        let pngUrl = _self.previewCanvas.toDataURL();
                        //console.log( pngUrl )
                        resolve( pngUrl )
                        //return pngUrl
                }
                img.onerror = reject
                img.src = URL.createObjectURL( b );

		})
	}

	createPreviewImage( blob ) {
		//console.log('createPreviewImage')

		let _self = this

		this.previewCanvas.width = this.previewWidth
		this.previewCanvas.height = this.previewHeight


		return new Promise((resolve, reject) => {

			var img = new Image();
			//console.log( this.previewCanvas )
    		var ctx = _self.previewCanvas.getContext('2d');

    		img.onload = function () {
        		ctx.drawImage(img,0,0, _self.previewWidth, _self.previewHeight);
        		let pngUrl = _self.previewCanvas.toDataURL();
        		//console.log( pngUrl )
        		resolve( pngUrl )
        		//return pngUrl
    		}
    		img.onerror = reject
    		img.src = URL.createObjectURL( blob );
    	})
	}
}
