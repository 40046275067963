export default class jobQueue {

/*
	{
		jobType: "upload",
		data: {
			
		}
	}
*/


	constructor( uimanager, crypto ) {

		this.uimanager = uimanager;
		this.filecrypto = crypto
		this.q = []
		this.activeTask = false

		this.jobQueueDisplay = document.getElementById("jobQueueDisplay")

		this.jobTimer = setInterval(this.timerJob, 10000, this);
	}

	rebuildJobUi() {
		//return
		this.jobQueueDisplay.innerHTML = '';

		let _self = this

		this.q.forEach(function(item){
			let jobDiv = document.createElement("div");
			jobDiv.classList.add("jobDiv");
			jobDiv.innerHTML = item.jobType;

			let jobDivOperations = document.createElement("div");
			jobDivOperations.classList.add("jobDivOperations")

			let jobDivProgress = document.createElement("div");
			jobDivProgress.classList.add("jobDivProgress")
			jobDivProgress.innerHTML = 'progress'

			let jobDivProgressInner = document.createElement("div");
			jobDivProgressInner.classList.add("jobDivProgressInner")
			jobDivProgressInner.id = 'job_progress_'+item.guid
			jobDivProgress.append( jobDivProgressInner )

			let jobDivCancel = document.createElement("div");
			jobDivCancel.classList.add("jobDivCancel")
			jobDivCancel.innerHTML = 'X'

			jobDivOperations.append(jobDivProgress)
			jobDivOperations.append(jobDivCancel)

			jobDiv.append( jobDivOperations )

			_self.jobQueueDisplay.append( jobDiv )
		})
	}

	timerJob( _self ) {
		_self.run()
		//_self.rebuildJobUi() // Deleteds running jobs
	}

	addJob( job ) {
		job.guid = this.generate_uuidv4()
		this.q.push( job )
		if( !this.activeTask ) this.run()
	}

	run() {
		if( !this.q.length || this.activeTask ) return;

		this.rebuildJobUi()

		var job = this.q.pop();

		console.log('Running '+job.jobType+' job.');
		switch( job.jobType ) {
			case 'upload':
				this.uploadJob( job )
				break;
			case 'download':
				this.downloadJob( job )
				break;
		}
	}

	async uploadJob( job ) {
		const uploadProgress = document.getElementById('job_progress_'+job.guid);

		let fullPath = 'https://storage.octostorage.io' + job.data.cwd + job.data.filename; 
		console.log( fullPath )

		let formData = new FormData();
    	formData.append("upload", job.data.blob, job.data.filename)

		const xhr = new XMLHttpRequest();
  		const success = await new Promise((resolve) => {
    		xhr.upload.addEventListener("progress", (event) => {
      			if (event.lengthComputable) {
        			console.log("upload progress:", event.loaded / event.total);
        			uploadProgress.style.width = String((event.loaded / event.total) * 100) + '%';
      			}
    		});
    		xhr.addEventListener("progress", (event) => {
      			if (event.lengthComputable) {
        			console.log("download progress:", event.loaded / event.total);
        			downloadProgress.value = event.loaded / event.total;
      			}
    		});
    		xhr.addEventListener("loadend", () => {
      			resolve(xhr.readyState === 4 && xhr.status === 200);
    		});
    		xhr.open("POST", fullPath, true);
    		//xhr.setRequestHeader("Content-Type", "application/octet-stream");
    		xhr.send(formData);
  		});
	}

	downloadJob( job ) {

		let _self = this;

		let path = ( job.data.url.startsWith('/') ) ? job.data.url : '/'+job.data.url;
		let fullPath = 'https://storage.octostorage.io' + path; 

		fetch( fullPath , {
			method: "GET",
			mode: "cors",
		})
		.then(response => {
   			if(response.ok){
      			//alert(response.headers.get("content-disposition"));
      			return response.blob();
   			}

   			throw new Error("Network response was not OK.");
		})
		.then(async (blob) => {
			console.log( blob )
   			//var url = new URL.createObjectURL(blob);
   			let encBlob = await _self.filecrypto.decryptfile( blob );
   			console.log( encBlob )

			var url  = window.URL.createObjectURL(encBlob);
    		window.location.assign(url);

   			//encBlob.then(async (data) => {
   			//	console.log(data)
   			//	let url = URL.createObjectURL( data );
   			//	console.log( url )
   			//})
   			//var url = window.URL.createObjectURL( encBlob );
   			//console.log( url )
   			//window.location = url
		})
	}

	generate_uuidv4() {
		var dt = new Date().getTime();
   		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
   			function( c ) {
      			var rnd = Math.random() * 16;//random number in range 0 to 16
      			rnd = (dt + rnd)%16 | 0;
      			dt = Math.floor(dt/16);
      			return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
   		});
	}
}
